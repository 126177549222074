var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Filled" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeFill) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-tabs",
        { attrs: { "content-class": "pt-1", fill: "" } },
        [
          _c(
            "b-tab",
            { attrs: { title: "Home" } },
            [
              _c("b-card-text", [
                _vm._v(
                  "Biscuit powder jelly beans. Lollipop candy canes croissant icing chocolate cake. Cake fruitcake powder pudding pastry."
                )
              ]),
              _c("b-card-text", [
                _vm._v(
                  "Tootsie roll oat cake I love bear claw I love caramels caramels halvah chocolate bar. Cotton candy gummi bears pudding pie apple pie cookie. Cheesecake jujubes lemon drops danish dessert I love caramels powder."
                )
              ])
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { active: "", title: "Profile" } },
            [
              _c("b-card-text", [
                _vm._v(
                  " Tootsie roll oat cake I love bear claw I love caramels caramels halvah chocolate bar. Cotton candy gummi bears pudding pie apple pie cookie. Cheesecake jujubes lemon drops danish dessert I love caramels powder. "
                )
              ]),
              _c("b-card-text", [
                _vm._v(
                  " Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Toffee cotton candy liquorice. Oat cake lemon drops gingerbread dessert caramels. Sweet dessert jujubes powder sweet sesame snaps. "
                )
              ])
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Messages" } },
            [
              _c("b-card-text", [
                _vm._v(
                  " Biscuit powder jelly beans. Lollipop candy canes croissant icing chocolate cake. Cake fruitcake powder pudding pastry. Oat cake lemon drops gingerbread dessert caramels.Lollipop croissant caramels jelly-o bonbon. Marshmallow tiramisu carrot cake pudding bonbon icing. "
                )
              ]),
              _c("b-card-text", [
                _vm._v(
                  " Sugar plum wafer lollipop. Tiramisu danish apple pie powder cookie. Macaroon marzipan toffee carrot cake biscuit cake dragée soufflé. Donut danish cookie sweet topping halvah carrot cake chocolate bonbon. "
                )
              ])
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Settings" } },
            [
              _c("b-card-text", [
                _vm._v(
                  " Tootsie roll oat cake I love bear claw I love caramels caramels halvah chocolate bar. Cotton candy gummi bears pudding pie apple pie cookie. Cheesecake jujubes lemon drops danish dessert I love caramels powder. "
                )
              ]),
              _c("b-card-text", [
                _vm._v(
                  " Dragée jelly-o pie halvah pudding bear claw. Carrot cake pie toffee pastry candy canes. Ice cream tiramisu chocolate bar apple pie jujubes croissant toffee. Sweet roll chocolate bar pastry macaroon jelly-o. Jujubes pudding lemon drops dessert cake. Pudding cupcake chupa chups brownie. "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }