var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Default" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDefault) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-tabs",
        [
          _c(
            "b-tab",
            { attrs: { title: "Home" } },
            [
              _c("b-card-text", [
                _vm._v(
                  " Candy canes donut chupa chups candy canes lemon drops oat cake wafer. Cotton candy candy canes marzipan carrot cake. Sesame snaps lemon drops candy marzipan donut brownie tootsie roll. Icing croissant bonbon biscuit gummi bears. Pudding candy canes sugar plum cookie chocolate cake powder croissant. "
                )
              ]),
              _c("b-card-text", [
                _vm._v(
                  " Carrot cake tiramisu danish candy cake muffin croissant tart dessert. Tiramisu caramels candy canes chocolate cake sweet roll liquorice icing cupcake. Candy cookie sweet roll bear claw sweet roll. "
                )
              ])
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { active: "", title: "Service" } },
            [
              _c("b-card-text", [
                _vm._v(
                  " Biscuit macaroon sugar plum sesame snaps oat cake halvah fruitcake pudding cotton candy. Cheesecake tart wafer soufflé. Chocolate marzipan donut pie soufflé dragée cheesecake. Gummi bears dessert croissant chocolate jujubes fruitcake. Pie cupcake halvah. "
                )
              ]),
              _c("b-card-text", [
                _vm._v(
                  "Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum bonbon powder. Macaroon jujubes ice cream sugar plum lollipop wafer caramels. Cheesecake chocolate tart cake gingerbread fruitcake cake candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate cake gummi bears."
                )
              ])
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Disabled", disabled: "" } },
            [_c("b-card-text", [_vm._v(" Carrot cake dragée chocolate. ")])],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Account" } },
            [
              _c("b-card-text", [
                _vm._v(
                  " Chocolate croissant cupcake croissant jelly donut. Cheesecake toffee apple pie chocolate bar biscuit tart croissant. Lemon drops danish cookie. Oat cake macaroon icing tart lollipop cookie sweet bear claw. "
                )
              ]),
              _c("b-card-text", [
                _vm._v(
                  " Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies dragée. Chocolate bar liquorice cheesecake cookie chupa chups marshmallow oat cake biscuit. Dessert toffee fruitcake ice cream powder tootsie roll cake. "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }