var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Lazy loading tab content" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeLazy) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-tabs",
        { attrs: { "content-class": "mt-1" } },
        [
          _c(
            "b-tab",
            { attrs: { title: "Regular tab" } },
            [
              _c(
                "b-alert",
                { staticClass: "mb-0", attrs: { variant: "info", show: "" } },
                [
                  _c("div", { staticClass: "alert-body" }, [
                    _vm._v(" I'm always mounted ")
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Lazy tab", lazy: "" } },
            [
              _c(
                "b-alert",
                { staticClass: "mb-0", attrs: { variant: "info", show: "" } },
                [
                  _c("div", { staticClass: "alert-body" }, [
                    _vm._v(" I'm lazy mounted! ")
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }