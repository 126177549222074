var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Dynamic tabs + tabs-end slot" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDynamic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-tabs",
        {
          scopedSlots: _vm._u([
            {
              key: "tabs-end",
              fn: function() {
                return [
                  _c(
                    "b-nav-item",
                    {
                      attrs: { role: "presentation" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.newTab($event)
                        }
                      }
                    },
                    [_c("b", [_vm._v("+")])]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "empty",
              fn: function() {
                return [
                  _c("div", { staticClass: "text-center text-muted" }, [
                    _vm._v(" There are no open tabs"),
                    _c("br"),
                    _vm._v(" Open a new tab using the "),
                    _c("b", [_vm._v("+")]),
                    _vm._v(" button above. ")
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        _vm._l(_vm.tabs, function(i) {
          return _c(
            "b-tab",
            { key: "dyn-tab-" + i, attrs: { title: "Tab " + i } },
            [
              _vm._v(" Tab contents " + _vm._s(i) + " "),
              _c(
                "b-button",
                {
                  staticClass: "float-right",
                  attrs: { size: "sm", variant: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.closeTab(i)
                    }
                  }
                },
                [_vm._v(" Close tab ")]
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }